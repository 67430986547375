import { Routes, Route } from 'react-router-dom';
import { Grid, Box, Typography, Link, useTheme } from '@mui/material';

import { Login } from 'pages/auth/login';
import { Verification } from 'pages/auth/verification';
import { NotFoundCard } from 'pages/common/404';
import { RebrandingModal } from 'components/common/rebrandingModal';

import { PublicRoute } from 'components/publicRoute';
import { ExternalLink } from 'components';
import { paths } from 'consts';
import logo from 'logo-light.png';

export const AuthPanel = () => {
  const theme = useTheme();
  return (
    <PublicRoute>
      <Box sx={{ width: '100vw', height: '100vh', backgroundColor: 'background.content' }}>
        <Grid container alignItems='flex-start' justifyContent='space-around' sx={{ height: 88, paddingTop: '40px' }}>
          <Link href='https://datai.network/' target='_blank' rel='noreferrer' underline='none'>
            <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
              <Typography variant='body1' sx={{ fontWeight: 500, marginRight: 1 }} color='text.primary'>
                Datai Website
              </Typography>
              <ExternalLink color={theme.palette.text.primary} />
            </Grid>
          </Link>
          <Grid container flexDirection='column' alignItems='center' sx={{ width: 'fit-content' }}>
            <img src={logo} alt='logo' style={{ width: 200 }} />
          </Grid>
          <Link href='https://valk-1.gitbook.io/merlin-v2-api-documentation/reference/welcome' target='_blank' rel='noreferrer' underline='none'>
            <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
              <Typography variant='body1' sx={{ fontWeight: 500, marginRight: 1 }} color='text.primary'>
                API Docs
              </Typography>
              <ExternalLink color={theme.palette.text.primary} />
            </Grid>
          </Link>
        </Grid>
        <Box sx={{ height: 'calc(100vh - 88px)', width: '100vw' }}>
          <Grid container flexDirection='column' alignItems='center' justifyContent='center' sx={{ width: 822, height: '100%', margin: '0 auto' }}>
            <Routes>
              <Route path={paths.login.path} element={<Login />} />
              <Route path={`/${paths.varification.path}`} element={<Verification />} />
              <Route path={paths.any.path} element={<NotFoundCard customRedirectPath={`/${paths.auth.path}`} />} />
            </Routes>
          </Grid>
        </Box>
      </Box>
      <RebrandingModal />
    </PublicRoute>
  );
};
